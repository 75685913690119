import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";

import { DashboardProvider } from "./context/DashboardContext";
import { ReportBrandProvider } from "./context/ReportBrandContext";
import { ReportInfluencerProvider } from "./context/ReportInfluencerContext";
import { ReportSpokespersonProvider } from "./context/ReportSpokespersonContext";
import { ReportTopicsProvider } from "./context/ReportTopicsContext";
import GeoByJourno from "./components/GEO/GeoCity/GeoByJourno";
import ErrorPage from "./components/ErrorPage";
const Geo = lazy(() => import("./components/GEO/GeoCity/Geo"));

const Instagram = lazy(() => import("./components/Social/Instagram/Instagram"));
const Twitter = lazy(() => import("./components/Social/Twitter/Twitter"));

const SmartProfile = lazy(() =>
  import("./components/SmartProfile/SmartProfile")
);
const Dashboard2 = lazy(() => import("./components/Dashboard2/Dashboard2"));

const BeforeLogin = lazy(() => import("./components/BeforeLogin/BeforeLogin"));
const BeforeForgotPassword = lazy(() =>
  import("./components/ForgotPassword/BeforeForgotPassword")
);
const BeforeResetPassword = lazy(() =>
  import("./components/ForgotPassword/BeforeResetPassword")
);
const AllMedia = lazy(() => import("./components/DataConfig/AllMedia"));
const Esg = lazy(() => import("./components/ESG/Esg"));
const AccountSetting = lazy(() =>
  import("./components/AccountSettings/AccountSetting")
);
const ChangePassword = lazy(() =>
  import("./components/AccountSettings/ChangePassword")
);
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const JP = lazy(() => import("./components/JournalistProfile/JP"));
const BeatWatch = lazy(() => import("./components/BeatWatch/BeatWatch"));
const UserAuthCheck = lazy(() => import("./components/UserAuth/UserAuthCheck"));
const PersonalInfo = lazy(() =>
  import("./components/AccountSettings/PersonalInfo")
);
const Loader = lazy(() => import("./components/Loader/Loader"));
const AllSettings = lazy(() => import("./components/DataConfig/AllSettings"));
const AllState = lazy(() => import("./components/DataConfig/AllState"));
const AllBeat = lazy(() => import("./components/DataConfig/AllBeat"));
const AllCompetitors = lazy(() =>
  import("./components/DataConfig/AllCompetitors")
);
const AllTopics = lazy(() => import("./components/DataConfig/AllTopics"));
const AllBrands = lazy(() => import("./components/DataConfig/AllBrands"));
const AllSpokesPerson = lazy(() =>
  import("./components/DataConfig/AllSpokesPerson")
);
const TopicsLanding = lazy(() =>
  import("./components/Reports/Topics/TopicsLanding")
);
const TopicsDetail = lazy(() =>
  import("./components/Reports/Topics/TopicsDetail")
);
const FullArticle = lazy(() => import("./components/BeatWatch/FullArticle"));
const ConfigureCRM = lazy(() =>
  import("./components/MyCRM/PreConfigCRM/ConfigureCRM")
);
const BrandsLanding = lazy(() =>
  import("./components/Reports/Brands/BrandsLanding")
);
const InfluencerLanding = lazy(() =>
  import("./components/Reports/Influencer/InfluencerLanding")
);
const SpokespersonLanding = lazy(() =>
  import("./components/Reports/Spokesperson/SpokespersonLanding")
);
const MediaTypeOutlet = lazy(() =>
  import("./components/MediaTypes/MediaTypeOutlet")
);
const JournalistByOutlet = lazy(() =>
  import("./components/MediaTypes/JournalistByOutlet")
);
const ListTracking = lazy(() => import("./components/Campaign/ListTracking"));
const CampaignList = lazy(() => import("./components/Campaign/CampaignList"));
const CampaignManager = lazy(() =>
  import("./components/Campaign/CampaignManager")
);
const JournalistSearch = lazy(() =>
  import("./components/JournalistSearch/JournalistSearch")
);
const JournalistByBeat = lazy(() =>
  import("./components/MediaTypes/JournalistByBeat")
);
const OutletEditor = lazy(() =>
  import("./components/MediaTypes/OutletCategory/OutletEditor")
);
const BureauChief = lazy(() =>
  import("./components/MediaTypes/OutletCategory/BureauChief")
);
const Columnist = lazy(() =>
  import("./components/MediaTypes/OutletCategory/Columnist")
);
const International = lazy(() =>
  import("./components/MediaTypes/OutletCategory/International")
);
const Supplements = lazy(() =>
  import("./components/MediaTypes/OutletCategory/Supplements")
);
const SupplementJourno = lazy(() =>
  import("./components/MediaTypes/OutletCategory/SupplementJourno")
);
const BeforeTeaTime = lazy(() =>
  import("./components/BeforeLogin/BeforeTeaTime")
);
const HomePage = lazy(() => import("./components/BeforeLogin/HomePage"));
const TermsCondition = lazy(() =>
  import("./components/BeforeLogin/TermsCondition")
);
const PrivacyPolicy = lazy(() =>
  import("./components/BeforeLogin/PrivacyPolicy")
);
const InstagramDetail = lazy(() =>
  import("./components/Social/Instagram/InstagramDetail")
);
const Podcast = lazy(() => import("./components/Social/Podcast/Podcast"));
const PodcastListing = lazy(() =>
  import("./components/Social/Podcast/PodcastListing")
);
const PodcastDetail = lazy(() =>
  import("./components/Social/Podcast/PodcastDetail")
);
const ResetPassword = lazy(() =>
  import("./components/ForgotPassword/ResetPassword")
);
const Mycrm = lazy(() => import("./components/MyCRM/Mycrm"));
const Skribe365Home = lazy(() =>
  import("./components/Skribe365Home/Skribe365Home")
);
const TopicReport = lazy(() =>
  import("./components/Reports/Topics/TopicsDetail")
);
const MediaTypes = lazy(() => import("./components/MediaTypes/MediaTypes"));
const BuildCampaign = lazy(() => import("./components/Campaign/BuildCampaign"));

const Favorites = lazy(() =>
  import("./components/Dashboard2/FavoritesHomePage")
);

const AppRoute = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <HomePage />
      </Suspense>
    ),
    errorElement: (
      <UserAuthCheck>
        <ErrorPage />
      </UserAuthCheck>
    ),
  },
  {
    path: "/tea-time",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeTeaTime />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeLogin />
      </Suspense>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeForgotPassword />
      </Suspense>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <Suspense fallback={<Loader />}>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    path: "/terms-condition",
    element: (
      <Suspense fallback={<Loader />}>
        <TermsCondition />
      </Suspense>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <Suspense fallback={<Loader />}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <Suspense fallback={<Loader />}>
        <BeforeResetPassword />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    ),

    children: [
      {
        path: "/beat-watch",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <BeatWatch />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      // {
      //   path: "dashboard",
      //   element: (
      //     <Suspense fallback={<Loader />}>
      //       <UserAuthCheck>
      //         <DashboardProvider>
      //           <Dashboard />
      //         </DashboardProvider>
      //       </UserAuthCheck>
      //     </Suspense>
      //   ),
      // },
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Dashboard2 />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "skribe365",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Skribe365Home />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "favorites",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Favorites />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "/",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <AllSettings />
            </UserAuthCheck>
          </Suspense>
        ),
        children: [
          {
            path: "Geo Location",
            element: (
              <Suspense fallback={<Loader />}>
                <AllState />
              </Suspense>
            ),
          },
          {
            path: "Media Types",
            element: (
              <Suspense fallback={<Loader />}>
                <AllMedia />
              </Suspense>
            ),
          },
          {
            path: "Beats",
            element: (
              <Suspense fallback={<Loader />}>
                <AllBeat />
              </Suspense>
            ),
          },
          {
            path: "Competitors",
            element: (
              <Suspense fallback={<Loader />}>
                <AllCompetitors />
              </Suspense>
            ),
          },
          {
            path: "topics",
            element: (
              <Suspense fallback={<Loader />}>
                <AllTopics />
              </Suspense>
            ),
          },
          {
            path: "Brand",
            element: (
              <Suspense fallback={<Loader />}>
                <AllBrands />
              </Suspense>
            ),
          },
          {
            path: "Spokes Person",
            element: (
              <Suspense fallback={<Loader />}>
                <AllSpokesPerson />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "esg",
        element: (
          <Suspense fallback={<Loader />}>
            <Esg />
          </Suspense>
        ),
      },
      {
        path: "/",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <AccountSetting />
            </UserAuthCheck>
          </Suspense>
        ),
        children: [
          {
            path: "accountSettings",
            element: (
              <Suspense fallback={<Loader />}>
                <PersonalInfo />
              </Suspense>
            ),
          },
          {
            path: "changePassword",
            element: (
              <Suspense fallback={<Loader />}>
                <ChangePassword />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "customcrm",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <ConfigureCRM />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "mycrm",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Mycrm />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "brandreport",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <ReportBrandProvider>
                <BrandsLanding />
              </ReportBrandProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "influencer",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <ReportInfluencerProvider>
                <InfluencerLanding />
              </ReportInfluencerProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "spokesperson",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <ReportSpokespersonProvider>
                <SpokespersonLanding />
              </ReportSpokespersonProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "topicsreport",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <ReportTopicsProvider>
                <TopicsLanding />
              </ReportTopicsProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "topicsdetail",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <ReportTopicsProvider>
                <TopicsDetail />
              </ReportTopicsProvider>
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistProfile/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <SmartProfile />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "beat-watch/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "dashboard/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "brandreport/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "influencer/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "spokesperson/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <FullArticle />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalist-search",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <JournalistSearch />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "media-types",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <MediaTypes />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "mediaTypeOutlet/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <MediaTypeOutlet />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistByBeat/:selected",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <JournalistByBeat />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "geo/:cityId",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Geo />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "geobyJourno",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <GeoByJourno />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-manager",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <CampaignManager />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-list/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <CampaignList />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "list-Tracking/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <ListTracking />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "campaign-action",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <BuildCampaign />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "journalistByOutlet/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <JournalistByOutlet />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "OutletEditor/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <OutletEditor />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "BureauChief/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <BureauChief />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "Columnist/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Columnist />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "Supplements/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Supplements />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "supplementJourno/:supplementId/:outletId/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <SupplementJourno />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "International/:outletId/:id/:name",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <International />
            </UserAuthCheck>
          </Suspense>
        ),
      },

      {
        path: "X",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Twitter />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "instagram",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Instagram />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "insta-detail/:insta",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <InstagramDetail />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "podcast",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <Podcast />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "podcast-listing/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <PodcastListing />
            </UserAuthCheck>
          </Suspense>
        ),
      },
      {
        path: "podcast-detail/:id",
        element: (
          <Suspense fallback={<Loader />}>
            <UserAuthCheck>
              <PodcastDetail />
            </UserAuthCheck>
          </Suspense>
        ),
      },
    ],
  },
]);

export default AppRoute;
