import React from "react";
import { Link, useNavigate } from "react-router-dom";
class ErrorBoundary extends React.Component {
  state = {
    error: "",
  };

  static getDerivedStateFromError(error) {
    return {
      error: error,
    };
  }
  componentDidCatch(error, info) {
    console.log("error is", error);
    console.log("error is", info);
  }

  render() {
    if (this.state.error) {
      return (
        <div className="mt-28 flex items-center justify-center">
          <div className="text-center">
            <span className="material-icons-outlined small-icon ml-auto text-[#FF3EA5]">
              info
            </span>
            <h2 className="mb-3 text-4xl font-semibold text-gray-600">
              Something went wrong
            </h2>
            <p className="text-md mb-4 text-xl text-gray-600">
              There was a problem processing the request. Please try again.
            </p>

            <a
              href="/media-types"
              className="cursor-pointer bg-[#FF3EA5] px-3 py-2 font-medium text-white"
            >
              Return to Home Page
            </a>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
